import css from './Improvement.module.scss'
import global from '../../../styles/Global.module.scss'
import Link from "next/link"
import {useEffect, useRef, useState} from "react";
import useVisible from "../../../hooks/useVisible";
import {SelectImage} from "../../../actions/util"

const Improvement = ({title, name, text, url, image1, image2}) => {

    //animation
    const banner = useRef(null)
    const {isVisible: isVisibleBanner} = useVisible(banner, 1)
    const pic = useRef(null)
    const {isVisible: isVisiblePic} = useVisible(pic)
    //

    const [visible, setVisible] = useState(false)
    const [src, setSrc] = useState('')
    const [visibleTwo, setVisibleTwo] = useState(false)
    const [srcTwo, setSrcTwo] = useState('')

    const img = useRef(null)
    const img_data = SelectImage("/files/images/main/main-improv-1.jpg", image1)
    const imgTwo = useRef(null)
    const img_dataTwo = SelectImage("/files/images/main/main-improv-2.jpg", image2)

    useEffect(() => {
        isVisiblePic && setSrc(img.current.getAttribute('data-src'))
        isVisiblePic && setSrcTwo(imgTwo.current.getAttribute('data-src'))
    }, [isVisiblePic])

    const loadImage = () => {
        setVisible(true)
    }

    const loadImageTwo = () => {
        setVisibleTwo(true)
    }

    return (
        <section className={css.improvement}>
            <div className={visible && isVisibleBanner ? `${css.banner}` : `${css.banner} ${css.start}`} ref={banner}>
                <h2 className='main-page__title main-page__improvement' dangerouslySetInnerHTML={{__html:title}}></h2>
                <img src={src} data-src={img_data} alt={""} ref={img} onLoad={() => loadImage()} width={'1920'} height={'861'}/>
            </div>
            <div className={`${global.section} ${css.content}`} ref={pic}>
                <img src={srcTwo} data-src={img_dataTwo} className={visibleTwo && isVisiblePic ? `${css.pic}` : `${css.pic} ${css.start}`} alt={""} ref={imgTwo} onLoad={() => loadImageTwo()} width={'640'} height={'525'}/>
                <div className={visibleTwo && isVisiblePic ? `${css.text}` : `${css.text} ${css.start}`}>
                    <div dangerouslySetInnerHTML={{__html:text}}></div>
                    <Link href={`/blagoustrojstvo`}><a className={`${css.button} btn`}>{name}</a></Link>
                </div>
            </div>
        </section>
    )
}

export default Improvement